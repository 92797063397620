import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ModalForm1 = ({ show, handleClose, csvData }) => {
  return (
    <Modal id="loginModal" show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Distribuidoras</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Não tem acesso ou não se lembra, selecione sua distribuidora para criar ou recuperar seu acesso:</p>
        {csvData.map((item, index) => (
          item.Nome !== '(SELECIONE SUA DISTRIBUIDORA)' && (
            <Button 
              key={index} 
              variant="light" 
              className="m-2 text-start text-dark"
              onClick={() => window.open(item.Site, '_blank')}
              style={{ minWidth: '200px' }}
            >
              {item.Nome}
            </Button>
          )
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalForm1;
