import React, { useState } from 'react';
import slide1 from '../img/slide1.jpg';
import logoImage from '../img/logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Spinner } from 'react-bootstrap';

const HeroSection = ({ handleShowModal1, isLoading, setIsLoading, setShowSlide2, csvData }) => {
  const [formData, setFormData] = useState({
    distribuidora: '',
    login: '',
    senha: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const generateHash = async (data) => {
    const encoder = new TextEncoder();
    const dataBuffer = encoder.encode(data);
    const hashBuffer = await crypto.subtle.digest('SHA-256', dataBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const combinedData = `${formData.distribuidora}${formData.login}${formData.senha}`;
      const hash = await generateHash(combinedData);
      const formUrl = 'https://script.google.com/macros/s/AKfycbzujTDfOgYgZog-bpXjhf1-HIfeaLCsFxV30a0sANfJUo-3lCJ7udY5ZXqyYqkcvWToQA/exec';

      const postResponse = await fetch(formUrl, {
        method: 'POST',
        body: JSON.stringify({ ...formData, hash }),
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!postResponse.ok && postResponse.type !== 'opaque') {
        throw new Error('Failed to post form data');
      }

      const csvUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTvbmzFIGdriUKzjuDQm1oeUzgsJNbdGteZcRc7Qhq8QaUpje2e6GGZBrMvVVvWDiQyDAzQa1pcUZkr/pub?gid=1270452625&single=true&output=csv';

      const response = await fetch(csvUrl);
      if (!response.ok) {
        throw new Error('Failed to fetch CSV');
      }

      const csvText = await response.text();
      const rows = csvText.split('\n').map(row => row.replace(/\r/g, '').split(','));
      const headers = rows[0];
      const dataRows = rows.slice(1);

      const hashIndex = headers.indexOf('Hash');
      const statusIndex = headers.indexOf('Status');

      const matchedRow = dataRows.find(row => row[hashIndex] === hash);
      let status;
      if (matchedRow) {
        status = matchedRow[statusIndex];
      } else {
        status = 'Pendiente';
      }

      setShowSlide2(status);

    } catch (error) {
      alert('Erro ao enviar o formulário', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="slide" style={{ minHeight: '90vh' }}>
      <div className="slide-background">
        <img src={slide1} alt="Hero" className='right' />
      </div>
      <div className="container-fluid">
        <div className='row'>
          <div className="col-md-4 offset-md-1 col-sm-12 text-white text-center">
            <img src={logoImage} className='img' alt="Logo" style={{ width: '280px' }} />
            <div className='mb-5'>
              <p className='text-start mx-auto' style={{ maxWidth: '180px' }}>Energia mais <b>barata</b><br />
                mais <b>sustentável</b><br />
                mais <b>acessível!</b></p>
            </div>
            <div className='mb-5 text-start'>
              <p>Cadastre-se com o mesmo login e senha da sua distribuidora:</p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-3 text-start">
                <label htmlFor="distribuidora" className="form-label">Distribuidora</label>
                <select
                  className="form-control"
                  id="distribuidora"
                  name="distribuidora"
                  value={formData.distribuidora}
                  onChange={handleChange}
                  required
                >
                  {csvData.map((item, index) => (
                    <option key={index} value={item.Nome}>{item.Nome}</option>
                  ))}
                </select>
              </div>
              <div className="mb-3 text-start">
                <label htmlFor="login" className="form-label">Login da distribuidora</label>
                <input
                  type="text"
                  className="form-control"
                  id="login"
                  name="login"
                  value={formData.login}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3 text-start">
                <label htmlFor="senha" className="form-label">Senha da distribuidora</label>
                <input
                  type="password"
                  className="form-control"
                  id="senha"
                  name="senha"
                  value={formData.senha}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="text-end">
                <button type="submit" className="btn btn-primary text-dark px-5 mt-3" disabled={isLoading}>
                  {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Continuar'}
                </button>
              </div>
              <div className="text-start mb-3 small">
                <a href="#" className="btn btn-link" onClick={handleShowModal1}>Não tenho login e senha</a> <br />
                <a href="#" className="btn btn-link" onClick={handleShowModal1}>Não me lembro do login e senha</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
