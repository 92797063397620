import React from 'react';
import logoImage from '../img/logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';

const Slide2 = ({ status }) => {
  return (
    <section className="slide" style={{ minHeight: '90vh' }}>
      <div className="container-fluid">
        <div className='row'>
          <div className="col-md-5 offset-md-1 col-sm-12 text-white text-start">
            <img src={logoImage} className='img' alt="Logo" style={{ width: '280px', marginBottom: '25px'}} />
            
            <hr/>

            <div className='my-5 h3'>
              <p>Acompanhe o status da sua contratação.</p>
            </div>

            <div style={{minHeight: '300px'}}>
              <p className='h5'>Status atual: {status || "Pendente"}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Slide2;
