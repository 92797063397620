import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import './App.css';
import HeroSection from './components/HeroSection';
import Slide2 from './components/Slide2';
import ModalForm1 from './components/ModalForm1';
import { Spinner } from 'react-bootstrap';

function App() {
  const [showModal1, setShowModal1] = useState(false);
  const [showSlide2, setShowSlide2] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState('');
  const [csvData, setCsvData] = useState([]);
  const [modalData, setModalData] = useState([]);

  const handleShowModal1 = () => setShowModal1(true);
  const handleCloseModal1 = () => setShowModal1(false);

  useEffect(() => {
    const fetchCsvData = async () => {
      try {
        const response = await fetch('https://docs.google.com/spreadsheets/d/e/2PACX-1vQ7LbOgJhxjlkWcEGHJGIzC9y9jQcSdaJlw1TWSQv97UzoXOHjaSQkSN0yA_iEvXK5PO9C_wzaz_eAe/pub?gid=571132988&single=true&output=csv');
        const text = await response.text();
        const rows = text.replace(/\r/g, '').split('\n').map(row => row.split(','));
        const headers = rows[0];
        const dataRows = rows.slice(1);
        const data = dataRows.map(row => {
          const obj = {};
          row.forEach((cell, index) => {
            obj[headers[index]] = cell;
          });
          return obj;
        });
        setCsvData([{ Nome: '(SELECIONE SUA DISTRIBUIDORA)', Site: '' }, ...data]);
        setModalData(data);  // Exclude the first item for modal data
      } catch (error) {
        console.error('Error fetching CSV data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCsvData();
  }, []);

  return (
    <div className="App">
      {!showSlide2 ? (
        <>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '90vh' }}>
              <Spinner animation="border" />
            </div>
          ) : (
            <HeroSection
              handleShowModal1={handleShowModal1}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setShowSlide2={(status) => { setStatus(status); setShowSlide2(true); }}
              csvData={csvData}
            />
          )}
          <ModalForm1 show={showModal1} handleClose={handleCloseModal1} csvData={modalData} />
        </>
      ) : (
        <Slide2 status={status} />
      )}
    </div>
  );
}

export default App;
